import axios from "axios";
import store from "../index";
import * as Storage from "../utils/storage";
import { clearCurrentUser } from "../actions/userActions";
import keepspaceApi from "../services/keepspaceApi";
//import adminApi from '../services/admin/adminApi';
//Default API Settings
const API_ROOT = process.env.url || "http://localhost:3000/"; // Don't worry, this isn't used in the KeepSpace project, this is simply from an example.
const TIMEOUT = 120000;
export const HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
  offset: new Date().getTimezoneOffset(),
};

class ApiService {
  constructor({
    baseURL = API_ROOT,
    timeout = TIMEOUT,
    headers = HEADERS,
    auth, withCredential = false
  }) {
    axios.defaults.withCredentials = withCredential;

    const client = axios.create({
      baseURL,
      timeout,
      headers,
      auth,
    });

    client.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        const errCodes = [401, 403];
        let status = error?.response?.status || null;
        if (!error && !status) status = 401;
        if (errCodes.includes(status)) {
          const path = window.location.pathname;
          if (path.includes("/admin") && !path.includes("auth")) {
            Storage.removeItem("_ADMIN");
            window.location.href = "/admin/auth/login";
          } else if (!path.includes("auth")) {
            store.dispatch(clearCurrentUser());
            const ADMIN = Storage.getItem("_ADMIN");
            Storage.clear();
            if (ADMIN) Storage.setItem("_ADMIN", ADMIN);
            window.location.href = "/auth/login";
          } else {
            return Promise.reject(error);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    this.client = client;
  }

  get(path, payload) {
    return this.client
      .get(path, {
        params: payload,
      })
      .then((response) => response.data);
  }

  post(path, payload, reqType) {
    return this.client.post(path, payload).then((response) => response.data);
  }
  postWithConfig(path, payload, config = {}, callback) {
    return this.client.post(path, payload, config).then((response) => {
      callback();
      return response.data;
    }).catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        throw(thrown.message);
      }
    })
  }

  // to return the headers as well
  postWithHeaders(path, payload) {
    return this.client.post(path, payload).then((response) => response);
  }

  // to return the headers as well
  getWithHeaders(path, payload) {
    return this.client.get(path, payload).then((response) => response);
  }

  put(path, payload) {
    return this.client.put(path, payload).then((response) => response.data);
  }

  patch(path, payload) {
    return this.client.patch(path, payload).then((response) => response.data);
  }

  delete(path, payload) {
    return this.client
      .delete(path, {
        data: payload,
      })
      .then((response) => response.data);
  }
  downloadFile(path, payload) {
    return this.client
      .post(path, payload, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((response) => response);
  }
}

export default ApiService;
